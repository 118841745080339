@import '../../../styles/variables';

.version-content__header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  max-height: auto;
  height: 4em;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.version-content-table {
  max-height: 560px;
  overflow: auto;

  table {
    border-collapse: separate;
    thead {
      background-color: $white;
      border: none;
      position: sticky;
      top: 0;
      z-index: 2; //CustomInput checkbox on Modal has zIndex of 1
      // custom border since border-bottom can't be used unless table border-collapse is set to "separate"
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: $gray-300;
        position: absolute;
        margin-bottom: 0;
      }
    }

    .cell-color {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
}

.version-badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 100%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  margin-right: 0.5em;

  @each $name, $glyph in $theme-colors {
    &--#{$name} {
      background-color: rgba($glyph, 0.2);
      color: $glyph;
    }
  }
}

.template-version-card {
  border: 1px solid $gray-300;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1rem;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  white-space: pre;
  min-width: 150px;
}
