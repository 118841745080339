.error-page {
  background: #023b89
    url('https://img.iafcertsearch.org/r/p/public/img_geo.png') no-repeat right
    bottom;
  text-align: center;
  padding: 5rem 0;
  flex-grow: 1;
  flex-direction: column;

  .error-msg {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 100;

    &__icon {
      color: #f7e800;
    }
  }

  h1 {
    font-size: 11rem;
    color: #fff;
    font-family: 'Helvetica Neue';
    line-height: 1;
  }

  h2 {
    font-size: 3rem;
    color: #fff;
    line-height: 2;
  }

  .error-btn {
    padding: 0.75rem 4.5rem !important;
  }
}
