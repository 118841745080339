@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,400i,600,700,900);
@import url(https://img.iafcertsearch.org/r/flags/24/flags.min.css);
.styles_error-page__2YsBf {
  background: #023b89 url("https://img.iafcertsearch.org/r/p/public/img_geo.png") no-repeat right bottom;
  text-align: center;
  padding: 5rem 0;
  flex-grow: 1;
  flex-direction: column;
}
.styles_error-page__2YsBf .styles_error-msg__3RIvA {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 100;
}
.styles_error-page__2YsBf .styles_error-msg__icon__2R3IO {
  color: #f7e800;
}
.styles_error-page__2YsBf h1 {
  font-size: 11rem;
  color: #fff;
  font-family: "Helvetica Neue";
  line-height: 1;
}
.styles_error-page__2YsBf h2 {
  font-size: 3rem;
  color: #fff;
  line-height: 2;
}
.styles_error-page__2YsBf .styles_error-btn__Pu7z_ {
  padding: 0.75rem 4.5rem !important;
}
.styles_version-content__header__1mNRn {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  max-height: auto;
  height: 4em;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_version-content-table__1je_A {
  max-height: 560px;
  overflow: auto;
}
.styles_version-content-table__1je_A table {
  border-collapse: separate;
}
.styles_version-content-table__1je_A table thead {
  background-color: #fff;
  border: none;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
.styles_version-content-table__1je_A table thead:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #dee2e6;
  position: absolute;
  margin-bottom: 0;
}
.styles_version-content-table__1je_A table .styles_cell-color__394Mw {
  background-color: rgba(0, 0, 0, 0.075);
}

.styles_version-badge__2_MU4 {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 100%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  margin-right: 0.5em;
}
.styles_version-badge--primary__1ApFz {
  background-color: rgba(0, 135, 211, 0.2);
  color: #0087d3;
}
.styles_version-badge--secondary__3gTsX {
  background-color: rgba(108, 117, 125, 0.2);
  color: #6c757d;
}
.styles_version-badge--success__EBazW {
  background-color: rgba(40, 167, 69, 0.2);
  color: #28a745;
}
.styles_version-badge--info__2JETP {
  background-color: rgba(23, 162, 184, 0.2);
  color: #17a2b8;
}
.styles_version-badge--warning__UlqJX {
  background-color: rgba(255, 193, 7, 0.2);
  color: #ffc107;
}
.styles_version-badge--danger__26SSA {
  background-color: rgba(220, 53, 69, 0.2);
  color: #dc3545;
}
.styles_version-badge--light__rUhKQ {
  background-color: rgba(248, 249, 250, 0.2);
  color: #f8f9fa;
}
.styles_version-badge--dark__Fkwy8 {
  background-color: rgba(52, 58, 64, 0.2);
  color: #343a40;
}

.styles_template-version-card__3N-1e {
  border: 1px solid #dee2e6;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1rem;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  white-space: pre;
  min-width: 150px;
}
